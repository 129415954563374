<template>
  <div class="settings-preferences">
    <!-- Account settings -->
    <div v-if="settings" class="account-settings">
      <div class="form-input">
        <label>{{ $t("common.language") }}</label>

        <v-select
          v-model="settings.locale"
          :items="[
            { text: 'English', value: 'en' },
            { text: 'German', value: 'de' },
          ]"
          hide-details
          outlined
          dense
          menu-props="offsetY"
          @change="updateLocale"
        />
      </div>

      <v-divider class="my-5" />

      <!-- Notifications -->
      <v-checkbox
        v-model="settings.notifications_off"
        class="mt-0"
        :label="$t('settings.notificationsOff')"
        :true-value="1"
        :false-value="0"
        @change="(v) => updateSettings('notifications_off', v)"
      />

      <!-- Notifications sound -->
      <v-checkbox
        v-model="settings.notification_sound_off"
        class="mt-0"
        :label="$t('settings.notificationsSoundOff')"
        :true-value="1"
        :false-value="0"
        @change="(v) => updateSettings('notification_sound_off', v)"
      />

      <v-divider class="mb-5 mt-2" />

      <!-- Newsletter  -->
      <v-checkbox
        v-model="settings.subscribe_to_newsletter"
        class="mt-0"
        :label="$t('settings.newsletter')"
        :true-value="1"
        :false-value="0"
        hide-details
        @change="(v) => updateSettings('subscribe_to_newsletter', v)"
      />
    </div>
  </div>
</template>

<script>
import setLocale from "@/utils/setLocale";

export default {
  computed: {
    settings() {
      return this.$user.account_settings;
    },
  },

  methods: {
    updateSettings(key, value) {
      const payload = {};
      payload[key] = value;

      return this.$store
        .dispatch("auth/updateAccountSettings", payload)
        .then(() => {
          this.$toast.success(this.$t("settings.updated"));
        })
        .catch(() => {
          this.$toast.error("Error while updating account settings.");
        });
    },

    updateLocale(locale) {
      this.updateSettings("locale", locale).then(() => {
        setLocale(locale);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
